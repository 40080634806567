<template>
    <div class="page">
        <el-form :model="inputForm" ref="ruleForm" label-width="130px" class="query-form">
            <el-form-item label="入馆须知：" prop="ticketingNotifyContent">
                <Editor :editortext="inputForm.ticketingNotifyContent" :disMenus="'video'" ref="notifyEditor" @changeHtml="notifyGetEditor"></Editor>
            </el-form-item>
            <el-form-item label="预约成功提示：" prop="ticketingSucceedContent">
                <Editor :editortext="inputForm.ticketingSucceedContent" :disMenus="'video'" ref="succeedEeditor" @changeHtml="succeedGetEditor"></Editor>
            </el-form-item>
            <el-form-item class="text_center p_b2">
                <el-button type="primary" size="small" @click="updataInfoData" v-noMoreClick>提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件

    export default {
        components: {Editor},
        data() {
            return {
                inputForm: {
                    ticketingNotifyContent: '',
                    ticketingSucceedContent: '',
                },
            }
        },
        created() {
            this.getInfoData()
        },
        methods: {
            // 获取内容
            getInfoData() {
                this.$axios(this.api.ticketing.getByTicketingPromptConfig).then((res) => {
                    if (res.status) {
                        this.$refs.notifyEditor.setHtml(res.data.ticketingNotifyContent)
                        this.$refs.succeedEeditor.setHtml(res.data.ticketingSucceedContent)
                        this.inputForm.ticketingNotifyContent = res.data.ticketingNotifyContent
                        this.inputForm.ticketingSucceedContent = res.data.ticketingSucceedContent

                    }
                })
            },
            // 修改内容
            updataInfoData() {
                this.$axios(this.api.ticketing.updateByTicketingPromptConfig, this.inputForm, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('提交成功');
                        this.getInfoData();
                    } else {
                        this.$message.error('提交失败');
                    }
                })
            },
            // 入馆须知
            notifyGetEditor(data) {
                this.inputForm.ticketingNotifyContent = data;
            },

            // 预约成功提示
            succeedGetEditor(data) {
                this.inputForm.ticketingSucceedContent = data;
            },
        }
    }
</script>

<style scoped>
    .el-form-item >>> .el-form-item__content {
        z-index: 20!important;
    }
</style>
